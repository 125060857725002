import { Button, Card, FileInput, Modal, Select, Title } from "@mantine/core";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { adminGetLeadClientList } from "../../functions/api";
import { useCsvParser } from "../../Hooks/useCsvParser";

export const CampaignScreen = () => {
  const [data, setData] = useState();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  const intl = useIntl();

  useEffect(() => {}, []);

  const handleClose = () => {
    setOpen(false);
    setSelected(null);
  };

  return (
    <Card>
      {/* <Modal opened={open} onClose={handleClose}>
        <Button
          style={{ backgroundColor: "dodgerblue" }}
          onClick={() => handleClose()}
        >
          {intl.formatMessage({ id: "save" })}
        </Button>
      </Modal> */}
      <Card withBorder>
        <div style={{ float: "right" }}>
          <Button
            onClick={() => (window.location.href = "admin-campaigns/new")}
            style={{ backgroundColor: "dodgerblue" }}
          >
            {intl.formatMessage({ id: "new" })}
          </Button>
        </div>
        <Title order={3}>{intl.formatMessage({ id: "campaigns" })}</Title>
      </Card>
    </Card>
  );
};

export const ManageCampaign = ({ id }) => {
  const intl = useIntl();
  const [projects, setProjects] = useState();
  const [parsedData, parseCsv] = useCsvParser();
  const [tmpCsv, setTmpCsv] = useState([]);
  const [error, setError] = useState(null);

  const handleFileChange = (file) => {
    setTmpCsv([]);
    setError(false);
    if (file) {
      parseCsv(file, (data) => {
        if (data.error) {
          setError(true);
        } else {
          const uniqueNumbers = new Set(
            data.map((item) => {
              let number = "" + item.phone;
              number = number.replace(/^\+371|^\371|^00371/, "");
              return number;
            })
          );
          setTmpCsv(Array.from(uniqueNumbers));
        }
      });
    }
  };

  useEffect(() => {
    adminGetLeadClientList().then((x) => {
      setProjects(
        x.map((y) => {
          return { label: y.name, value: y.lrid };
        })
      );
    });
  }, []);

  useEffect(() => {
    console.log(projects);
  }, [projects]);

  return (
    <Card>
      <Card withBorder>
        <Title order={3}>{intl.formatMessage({ id: "campaign" })}</Title>
        <Select
          dropdownPosition="bottom"
          data={projects && projects.length > 0 ? projects : []}
          label={intl.formatMessage({ id: "project" })}
          withinPortal
        />
        <FileInput
          onChange={(files) => handleFileChange(files[0])}
          multiple
          label="Upload files"
          placeholder="Upload files"
          accept=".csv,.txt"
        />
        {tmpCsv && tmpCsv.length > 0 && (
          <div>
            <strong>Unique numbers found:</strong> {tmpCsv.length}
          </div>
        )}
      </Card>
    </Card>
  );
};
