import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

export const AdminClientScreen = () => {
  const intl = useIntl();
  const { id } = useParams();
  const [data, setData] = useState();
  
  useEffect(() => {}, []);
  return <></>;
};
