import { useEffect, useState } from "react";
import { MultiSelect, Grid, Loader, Accordion, Button } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import {
  adminGetLeadClientList,
  adminGetLeadListSelect,
  getLeadClientList,
  getReportFilters,
  getReportLeadResults,
} from "../../functions/api";
import { FormattedMessage, useIntl } from "react-intl";
import { LeadListItem } from "../User/LeadScreen";

export const stateObject = {
  clientStatus: [],
  whoCreated: [],
  source: [],
  closedReason1: [],
  creationRange: [null, null],
  closeRange: [null, null],
  hasTask: null,
  closedReason2: [],
  taskStatus: [],
  assignedTo: [],
  completionRange: [null, null],
  closedRange: [null, null],
  deadlineRange: [null, null],
  showBy: null,
  calledTo: [],
  whoCalled: [],
  callNr: [],
  callState: null,
  status: [],
  createdBy: [],
  madeFor: [],
  dueDateRange: [null, null],
  paidDateRange: [null, null],
  system: [],
  sortBy: [],
};

const leadListx = [
  { value: "sungo", label: "SUNGO" },
  { value: "3", label: "Vertex" },
  { value: "1", label: "Testa kampaņa" },
  // { value: "erti", label: "Erti.lv Matrači" },
  { value: "jbmnj", label: "JBMNJ" },
  // { value: "majaelpo", label: "MajaElpo.lv" },
  { value: "lmrf", label: "LMRF.lv" },
  { value: "crm", label: "SOMECRM" },
  { value: "11", label: "Skaistuma studija" },
  { value: "7", label: "Auto Rīga" },
  { value: "12", label: "Skvoša skola" },
];

export const LeadsReport = () => {
  const intl = useIntl();
  const [values, setValues] = useState(stateObject);
  const [filters, setFilters] = useState(null);
  const [results, setResults] = useState([]);
  const [openo, setOpeno] = useState();
  const [leadList, setLeadList] = useState([]);
  const [modeList, setModeList] = useState();

  useEffect(() => {
    adminGetLeadListSelect().then((x) => {
      setLeadList(x);
    });
  }, []);
  useEffect(() => {
    getReportFilters().then((x) => setFilters(x));
  }, []);

  useEffect(() => {
    adminGetLeadClientList().then((x) => {
      setModeList(x);
    });
  }, []);

  const handleChange = (name) => (value) => {
    setValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const submitFilters = () => {
    getReportLeadResults(values).then((x) => setResults(x));
  };

  return (
    <>
      <div style={{ padding: 10 }}>
        {!filters ? (
          <Loader />
        ) : (
          <Grid>
            <Grid.Col xs={6}>
              <MultiSelect
                searchable
                data={filters ? filters?.users : []}
                label={intl.formatMessage({ id: "client_creator" })}
                placeholder={intl.formatMessage({ id: "select_status" })}
                value={values.whoCreated}
                onChange={handleChange("whoCreated")}
              />
            </Grid.Col>
            <Grid.Col xs={4}>
              <DatePickerInput
                allowSingleDateInRange
                clearable
                type="range"
                label={intl.formatMessage({ id: "creation_range" })}
                placeholder={intl.formatMessage({ id: "pick_date_range" })}
                value={values.creationRange}
                onChange={handleChange("creationRange")}
                mx="auto"
              />
            </Grid.Col>
            <Grid.Col xs={4}>
              <MultiSelect
                searchable
                data={leadList}
                label={intl.formatMessage({ id: "system" })}
                placeholder={intl.formatMessage({ id: "select_system" })}
                value={values?.system}
                onChange={handleChange("system")}
              />
            </Grid.Col>
            <Grid.Col xs={10}>
              <Button
                style={{ backgroundColor: "dodgerblue" }}
                onClick={() => submitFilters()}
              >
                <FormattedMessage id="submit" />
              </Button>
            </Grid.Col>
            <div style={{ width: "100%" }}>
              <Accordion value={openo} onChange={setOpeno}>
                {results.map((res) => (
                  <Accordion.Item
                    key={
                      res.month ? (
                        res.month
                      ) : (
                        <FormattedMessage id="ungrouped" />
                      )
                    }
                    value={
                      res.month ? (
                        res.month
                      ) : (
                        <FormattedMessage id="ungrouped" />
                      )
                    }
                  >
                    <Accordion.Control>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {/* <span>{res.month.substring(0, 7)}</span>{" "} */}
                        <span>
                          {res.month ? (
                            new Date(res.month)
                              .toLocaleDateString("en-GB")
                              .substring(3)
                          ) : (
                            <FormattedMessage id="without_date" />
                          )}
                        </span>{" "}
                        <span>{res.count}</span>
                      </div>
                    </Accordion.Control>
                    {openo ==
                      (res.month ? (
                        res.month
                      ) : (
                        <FormattedMessage id="ungrouped" />
                      )) &&
                      res.data.map((d) => {
                        return (
                          <Accordion.Panel key={d.lead_id}>
                            <LeadListItem d={d} modeList={modeList} />
                          </Accordion.Panel>
                        );
                      })}
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </Grid>
        )}
      </div>
    </>
  );
};
