import { useEffect, useState } from "react";
import { adminGetNumberList } from "../../functions/api";
import ClientTable from "../../Components/ClientTable";
import { ActionIcon, LoadingOverlay } from "@mantine/core";
import { Eye } from "tabler-icons-react";

export const AdminClientOverviewScreen = () => {
  const [nrs, setNrs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    adminGetNumberList().then((x) => {
      setNrs(x);
      setLoading(false);
    });
  }, []);

  const columns = [
    {
      key: "number",
      title: "number",
      searchable: true,
      render: (nrs) => nrs.number,
    },
    {
      key: "times_called",
      title: "times_called",
      searchable: true,
      render: (nrs) => nrs.times_called,
    },
    {
      key: "last_call",
      title: "last_call",
      searchable: true,
      render: (nrs) => nrs.last_call,
    },
    {
      key: "action",
      title: "",
      searchable: false,
      render: (nrs) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <a target="_blank" href={`/client/${nrs.cnid}`}>
            <ActionIcon color="green" size="lg" variant="light">
              <Eye />
            </ActionIcon>
          </a>
        </div>
      ),
    },
  ];

  return (
    <div style={{ position: "relative" }}>
      {loading && <LoadingOverlay visible={loading} />}
      <ClientTable clients={nrs} columns={columns} />
    </div>
  );
};
