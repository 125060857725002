import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  getPhoneNumbers,
  getReportFilters,
  updatePhoneNumber,
} from "../../functions/api";
import { Button, Modal, Select, TextInput } from "@mantine/core";
import ClientTable from "../../Components/ClientTable";
import { Pencil } from "tabler-icons-react";

export const NumberManagementScreen = () => {
  const intl = useIntl();
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [filters, setFilters] = useState();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    getReportFilters().then((x) => setFilters(x));
  }, []);

  useEffect(() => {
    getPhoneNumbers().then((x) => {
      setData(x);
      console.log(x);
    });
  }, [open, selected]);

  const handleNumberSave = () => {
    updatePhoneNumber(selected).then(() => {
      closeModal();
      //   successNotif(intl);
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelected({ ...selected, [name]: value });
  };

  const closeModal = () => {
    setOpen(false);
    setSelected(null);
  };

  const openModal = (data) => {
    setOpen(true);
    setSelected(data);
  };

  const columns = [
    {
      key: "",
      title: "",
      searchable: false,
      render: (client) => (
        <a style={{ cursor: "pointer" }} onClick={() => openModal(client)}>
          <Pencil />
        </a>
      ),
    },
    {
      key: "number",
      title: "number",
      searchable: true,
      render: (client) => client.caller_number,
    },
    {
      key: "assigned_to",
      title: "assigned_to",
      searchable: true,
      render: (client) => client.assigned_to,
    },
    // {
    //   key: "status",
    //   title: "status",
    //   searchable: true,
    //   render: (client) => (client.status ? client.status : "active"),
    // },
    {
      key: "api_key_part",
      title: "api_key_part",
      searchable: true,
      render: (client) =>
        client.api_key ? `${client.api_key.slice(0, 25)}...` : null,
    },
    {
      key: "status",
      title: "status",
      searchable: true,
      render: (client) => (client.status ? client.status : "active"),
    },
    // {
    //   key: "status",
    //   title: "status",
    //   searchable: false,
    //   render: (client) =>
    //     client.status && intl.formatMessage({ id: client.status }),
    // },
  ];

  return (
    <>
      <span style={{ fontSize: 12, paddingTop: 10, paddingBottom: 10 }}>
        <FormattedMessage id="nb_onlyfor" />{" "}
        <a
          style={{ color: "dodgerblue" }}
          href="https://teltel.io"
          target="_blank"
        >
          <FormattedMessage id="teltel" />
        </a>{" "}
        <FormattedMessage id="integration_and_numbers" />
      </span>
      <Modal
        opened={open}
        onClose={closeModal}
        title={intl.formatMessage({ id: "add_new_number" })}
        centered
      >
        <span style={{ fontSize: 12 }}>
          <FormattedMessage id="nb_onlyfor" />{" "}
          <a
            style={{ color: "dodgerblue" }}
            href="https://teltel.io"
            target="_blank"
          >
            <FormattedMessage id="teltel" />
          </a>{" "}
          <FormattedMessage id="integration_and_numbers" />
        </span>
        <TextInput
          value={selected && selected.caller_number}
          label={intl.formatMessage({ id: "number" })}
          name="caller_number"
          onChange={handleInputChange}
          style={{ paddingTop: 20 }}
        />
        <TextInput
          value={selected && selected.api_key}
          label={intl.formatMessage({ id: "api_key" })}
          name="api_key"
          onChange={handleInputChange}
          style={{ paddingTop: 10 }}
        />
        <Select
          value={selected && Number(selected.user_id)}
          searchable
          onChange={(x) => setSelected({ ...selected, ["user_id"]: x })}
          label={intl.formatMessage({ id: "user_for_this_num" })}
          name="user_id"
          data={filters && filters.users}
          style={{ paddingTop: 10 }}
        />
        {/* <Select
              style={{ paddingTop: 10 }}
              label="status"
              data={[
                { value: "disabled", label: "Disabled" },
                { value: "active", label: "Active" },
              ]}
            /> */}
        <Button
          variant="contained"
          style={{
            backgroundColor: "dodgerblue",
            color: "white",
            marginTop: 30,
            marginBottom: 20,
            float: "right",
          }}
          color="primary"
          onClick={handleNumberSave}
        >
          <FormattedMessage id="save" />
        </Button>
      </Modal>
      <div>
        <Button
          variant="contained"
          style={{ backgroundColor: "dodgerblue", color: "white" }}
          color="primary"
          onClick={() => setOpen(true)}
        >
          <FormattedMessage id="add_number" />
        </Button>
      </div>
      <ClientTable columns={columns} clients={!data.error ? data : []} />
    </>
  );
};
