import { Navigate } from "react-router-dom";
import {
  getToken,
  removeCallToken,
  removeData,
  removeGroup,
  removeToken,
  removeUserId,
} from "../../functions/tokens";
import { useContext, useEffect, useState } from "react";
import { WebPhoneContext } from "../../functions/WebPhoneContext";

export const Logout = ({ setLoggedin }) => {
  const { resetPhone } = useContext(WebPhoneContext);

  const [logout, setLogout] = useState(false);
  useEffect(() => {
    if (getToken() != null) {
      removeToken();
      removeGroup();
      removeData();
      removeUserId();
      removeCallToken();
      setLoggedin && setLoggedin(false);
      resetPhone();
      setLogout(true);
    }
  }, []);

  useEffect(() => {
    window.location.href = "/login";
  }, [logout]);

  return <div></div>;
};
