import { useEffect, useState } from "react";
import {
  adminGetLeadClient,
  adminGetLeadClientList,
  adminSaveLeadClient,
  adminVerifyLeadClientApi,
} from "../../functions/api";
import {
  Button,
  Card,
  ColorSwatch,
  Box,
  LoadingOverlay,
  Grid,
  TextInput,
  ColorInput,
  Select,
  Modal,
} from "@mantine/core";
import { FormattedMessage, useIntl } from "react-intl";
import { Pencil } from "tabler-icons-react";
import { useNavigate, useParams } from "react-router-dom";

export const AdminProjectScreen = () => {
  const intl = useIntl();

  const [clients, setClients] = useState([]);
  const [selected, setSelected] = useState(null);
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState(null);

  const openModal = (row) => {
    setSelected(row);
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
    setSelected(null);
  };

  const verifyApiData = () => {
    if (!/\/api\/v0\//.test(selected?.url)) {
      window.alert(intl.formatMessage({ id: "somecrm_only" }));
    } else {
      adminVerifyLeadClientApi({
        url: selected?.url?.replace("/api/v0/", "/api/v0/verify/"),
        token: selected.key,
      }).then((x) => {
        if (x.win) {
          window.alert(intl.formatMessage({ id: "api_correct" }));
        } else {
          window.alert(intl.formatMessage({ id: "api_incorrect" }));
        }
      });
    }
  };

  useEffect(() => {
    if (selected) {
      adminGetLeadClient(selected.lrid).then((x) => {
        setValues(x[0]);
        // setLoading(false);
      });
    } else {
      // setLoading(false);
    }
  }, [selected]);

  useEffect(() => {
    adminGetLeadClientList().then((x) => setClients(x));
  }, [open]);

  const handleChange = (name) => (value) => {
    setSelected((prevState) => ({ ...prevState, [name]: value }));
  };

  const saveLeadClient = async () => {
    adminSaveLeadClient(selected).then((x) => {
      if (x.win) {
        window.alert(intl.formatMessage({ id: "saved" }));
        // notifications.show({
        //   title: intl.formatMessage({ id: "saved" }),
        //   message: intl.formatMessage({ id: "saved_message" }),
        //   autoClose: 1000,
        // });
      }
    });
  };

  return (
    <div>
      <Modal
        size="calc(100vw - 3rem)"
        opened={open}
        onClose={() => closeModal()}
      >
        <div
          style={{
            fontSize: 24,
            fontWeight: 800,
            marginBottom: 10,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <FormattedMessage id="lead_client_management" />
        </div>
        <div>
          <Grid>
            <Grid.Col xs={6}>
              <TextInput
                placeholder="External client"
                label={<FormattedMessage id="name" />}
                withAsterisk
                value={selected?.name}
                onChange={(t) => handleChange("name")(t.target.value)}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <TextInput
                placeholder="https://api.com/send-here"
                withAsterisk
                label={<FormattedMessage id="link" />}
                value={selected?.url}
                disabled={selected?.status == "local"}
                onChange={(t) => handleChange("url")(t.target.value)}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <TextInput
                placeholder="Bearer 1234-key"
                withAsterisk
                label={<FormattedMessage id="key" />}
                value={selected?.key}
                disabled={selected?.status == "local"}
                onChange={(t) => handleChange("key")(t.target.value)}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <ColorInput
                withAsterisk
                label={<FormattedMessage id="color" />}
                value={selected?.color}
                onChange={(c) => handleChange("color")(c)}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <TextInput
                placeholder="1"
                label={<FormattedMessage id="index" />}
                withAsterisk
                value={selected?.index}
                onChange={(t) => handleChange("index")(t.target.value)}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <Select
                label={<FormattedMessage id="status" />}
                placeholder="Pick one"
                value={selected?.status}
                onChange={(t) => handleChange("status")(t)}
                disabled={selected?.status == "local"}
                data={[
                  {
                    value: "active",
                    label: intl.formatMessage({ id: "active" }),
                  },
                  {
                    value: "disabled",
                    label: intl.formatMessage({ id: "disabled" }),
                  },
                  {
                    value: "local",
                    disabled: true,
                    label: intl.formatMessage({ id: "local" }),
                  },
                ]}
              />
            </Grid.Col>
            <Grid.Col xs={12} />
            <Grid.Col xs={2}>
              <Button
                onClick={() => saveLeadClient()}
                fullWidth
                style={{
                  backgroundColor: "dodgerblue",
                  marginTop: 10,
                }}
              >
                <FormattedMessage id="save" />
              </Button>
            </Grid.Col>
            <Grid.Col xs={2}>
              <Button
                onClick={() => verifyApiData()}
                fullWidth
                style={{
                  backgroundColor: "dodgerblue",
                  marginTop: 10,
                }}
              >
                <FormattedMessage id="verify_connection" />
              </Button>
            </Grid.Col>
          </Grid>
        </div>
      </Modal>
      <Button
        variant="contained"
        style={{ marginTop: 10, backgroundColor: "dodgerblue", color: "white" }}
        color="primary"
        onClick={() => openModal()}
      >
        {" "}
        <FormattedMessage id="add" />
      </Button>
      {clients &&
        clients?.map((client) => (
          <Card withBorder mt={5}>
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              key={client.lrid}
            >
              <div>
                {client.index ? client.index + ". " : null} {client.name}
                <br />
                <div style={{ fontSize: 10 }}>
                  {client?.url?.length > 50
                    ? client?.url.slice(0, 50) + "..."
                    : client?.url}{" "}
                  <br />{" "}
                  {client?.key?.length > 50
                    ? client.key?.slice(0, 50) + "..."
                    : client.key}
                </div>
              </div>
              <div></div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ marginLeft: 10, marginRight: 10 }}>
                  <ColorSwatch color={client.color}></ColorSwatch>
                </div>
                {/* <a
                  href={`#`}
                  style={{ marginRight: 10, padding: 3 }}
                  title="Empty"
                >
                  <Trash color="red" />
                </a> */}
                <a
                  style={{ padding: 3, cursor: "pointer" }}
                  onClick={() => openModal(client)}
                >
                  <Pencil />
                </a>
              </div>
            </div>
          </Card>
        ))}
    </div>
  );
};
