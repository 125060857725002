import { Button, Card, FileInput, MultiSelect, Select } from "@mantine/core";
import { useEffect, useState } from "react";
import { useCsvParser } from "../../Hooks/useCsvParser";
import { adminGetLeadClientList, importNumbers } from "../../functions/api";
import { useIntl } from "react-intl";

export const ImportScreen = () => {
  const intl = useIntl();

  const [parsedData, parseCsv] = useCsvParser();
  const [tmpCsv, setTmpCsv] = useState([]);
  const [error, setError] = useState(null);
  const [projects, setProjects] = useState(null);
  const [selected, setSelected] = useState(null);

  const executeImport = async () => {
    const action = await importNumbers({ data: tmpCsv, project: selected });
    if (action) {
      setTmpCsv(null);
      window.alert(`Ievietoti ${action.count} jauni, unikāli ieraksti`);
    }
  };

  const handleFileChange = (file) => {
    setTmpCsv([]);
    setError(false);
    try {
      if (file) {
        parseCsv(file, (data) => {
          if (data.error) {
            setError(true);
          } else {
            const uniqueNumbers = new Set(
              data.map((item) => {
                let number = "" + item.phone;
                // number = number.replace(/^\+371|^\371|^00371/, "");
                number = number.replace(/[\s"'”]|^[\+371]|^371|^00371/g, "");
                //return number;
                return { number: number, comment: item.comment || null };
              })
            );
            setTmpCsv(Array.from(uniqueNumbers));
          }
        });
      } else {
        console.log("error");
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    adminGetLeadClientList().then((x) => {
      setProjects(
        x.map((y) => {
          return { label: y.name, value: y.lrid };
        })
      );
    });
  }, []);

  return (
    <Card>
      <Card withBorder>
        <FileInput
          onChange={(files) => handleFileChange(files[0])}
          multiple
          label="Upload files"
          placeholder="Upload files"
          accept=".csv,.txt"
        />
        {tmpCsv && tmpCsv.length > 0 && (
          <div>
            <strong>Unique numbers found:</strong> {tmpCsv.length}
          </div>
        )}
        {/* {tmpCsv && tmpCsv.length > 0 && (
          <div style={{marginTop: 20}}>
            <MultiSelect
              // xdescription="Šie numuri tiks ievietoti "
              multiple
              dropdownPosition="bottom"
              value={selected}
              data={proj && proj.length > 0 ? proj : []}
              label={intl.formatMessage({ id: "project" })}
              onChange={(x) => setSelected(x)}
              withinPortal
            />
          </div>
        )} */}
        {tmpCsv && tmpCsv.length > 0 && (
          <Select
            style={{ marginTop: 15 }}
            description="Projekts, kuram piesaistīt šos numurus"
            xmultiple
            dropdownPosition="bottom"
            value={selected}
            data={projects && projects.length > 0 ? projects : []}
            label={intl.formatMessage({ id: "projects" })}
            onChange={(x) => setSelected(x)}
            withinPortal
          />
        )}
        {tmpCsv && tmpCsv.length > 0 && (
          <div style={{ marginTop: 20, marginBottom: 5 }}>
            <Button
              onClick={() => executeImport()}
              style={{ backgroundColor: "dodgerblue" }}
            >
              Import
            </Button>
          </div>
        )}
      </Card>
    </Card>
  );
};
