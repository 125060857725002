import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  TextInput,
  Textarea,
  useMantineTheme,
  SegmentedControl,
  Dialog,
  Overlay,
  Grid,
  Title,
  LoadingOverlay,
} from "@mantine/core";
import {
  declineLeadEntry,
  getLastLeads,
  getLeadClientList,
  getLeadNumber,
  submitLead,
} from "../../functions/api";
import { Knob } from "primereact/knob";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";

import { FormattedMessage, useIntl } from "react-intl";
import { Mail, MessageCircle, Phone } from "tabler-icons-react";
import { CallButton } from "../../Components/CallButton";

const LeadScreen = () => {
  const intl = useIntl();
  const [form, setForm] = useState({
    name: "",
    surname: "",
    phoneNumber: "",
    email: "",
    comment: "",
    deadline: null,
    mode: "",
    loader: 100,
    cid: null,
    queid: null,
  });
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState({});
  const [found, setFound] = useState(null);

  const [incoming, setIncoming] = useState(false);

  const [showDialog, setShowDialog] = useState(false);
  const [countdown, setCountdown] = useState(6);

  const theme = useMantineTheme();
  const [working, setWorking] = useState(false);
  const [last, setLast] = useState([]);

  const [selected, setSelected] = useState(null);

  const [modeList, setModeList] = useState();

  useEffect(() => {
    getLeadClientList().then((x) => {
      if (x.length > 0) {
        setFound(true);
        setModeList(x);
        setForm({ ...form, mode: x[0].lrid });
        setSelected(x[0].lrid);
      } else {
        setFound(false);
      }
    });
  }, []);

  useEffect(() => {
    getLeadNumber({ project: form.mode }).then(
      (x) => {
        setForm({
          ...form,
          phoneNumber: x.number,
          preset: x.comment,
          cid: x.c_id,
          queid: x.que_id,
        });
      }
      //setForm({ ...form, phoneNumber: x.project })
    );
  }, [selected, success]);

  useEffect(() => {
    if (incoming == false) {
      getLeadNumber({ project: form.mode }).then(
        (x) => {
          setForm({
            ...form,
            phoneNumber: x.number,
            cid: x.c_id,
            queid: x.que_id,
          });
        }
        //setForm({ ...form, phoneNumber: x.project })
      );
    } else {
      setForm({
        name: "",
        surname: "",
        phoneNumber: "",
        email: "",
        comment: "",
        preset: "",
        deadline: null,
        mode: modeList[0].lrid,
        loader: 100,
        cid: null,
        queid: null,
      });
    }
  }, [incoming]);

  useEffect(() => {
    getLastLeads().then((x) => {
      setLast(x);
    });
  }, [success, errors]);

  useEffect(() => {
    let countdownInterval;

    if (showDialog) {
      countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }

    if (countdown === 0) {
      clearInterval(countdownInterval);
      handleCountdownComplete();
    }

    return () => {
      clearInterval(countdownInterval);
    };
  }, [showDialog, countdown]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    // validate email field
    if (name === "email") {
      const isValidEmail = /\S+@\S+\.\S+/.test(value);
      setErrors({ ...errors, email: !isValidEmail });
    } else {
      setErrors({ ...errors, [name]: false });
    }

    setForm({ ...form, [name]: value });
  };

  //   const handleDeadlineChange = (value) => {
  //     setForm({ ...form, deadline: value });
  //   };

  const handleModeChange = (value) => {
    setForm({
      ...form,
      mode: value,
      old: modeList.find((item) => item.value === value)?.redunant_mode,
    });
    setSelected(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (form.name == null || form.name === "") {
      window.alert(intl.formatMessage({ id: "must_enter_email_phone" }));
      return false;
    }

    if (
      (form.phoneNumber == null || form.phoneNumber === "") &&
      (form.email == null || form.email === "")
    ) {
      if (
        !window.confirm(
          intl.formatMessage({ id: "confirm_submit_no_deadline" })
        )
      ) {
        return false;
      }
    }

    if (form.comment == null || form.comment === "") {
      if (
        !window.confirm(intl.formatMessage({ id: "confirm_submit_no_descr" }))
      ) {
        return false;
      }
    }

    if (!form.email && !form.phoneNumber) {
      setErrors({ ...errors, email: true, phoneNumber: true });
      return false;
    }

    if (form.email && errors.email) {
      return false;
    }

    setShowDialog(true);
  };

  const handleDialogClose = () => {
    setShowDialog(false);
    setCountdown(5);
  };

  const handleCountdownComplete = async () => {
    setShowDialog(false);
    setSubmitting(true);

    try {
      if (working == false) {
        setWorking(true);
        const response = await submitLead(form);
        if (response) {
          setSuccess(true);
          setForm({
            name: "",
            surname: "",
            phoneNumber: "",
            email: "",
            preset: "",
            comment: "",
            deadline: null,
            mode: modeList[0].lrid,
            loader: 100,
            cid: null,
            queid: null,
          });
          setSelected(modeList[0].lrid);
          setCountdown(5);
          setWorking(false);
        }
      }
    } catch (error) {
      alert(error);
    }

    setSubmitting(false);
  };

  const submitFail = async (type) => {
    setShowDialog(false);
    setSubmitting(true);
    if (working == false) {
      await declineLeadEntry({ ...form, type: type }).then((x) => {
        setForm({
          name: "",
          surname: "",
          phoneNumber: "",
          email: "",
          comment: "",
          preset: "",
          deadline: null,
          mode: modeList[0].lrid,
          loader: 100,
          cid: null,
          queid: null,
        });
      });
      setCountdown(5);
      setSuccess(true);
      setWorking(false);
      setSubmitting(false);
      setSelected(modeList[0].lrid);
    }
  };

  if (found == null) {
    return <LoadingOverlay />;
  }

  if (found == false) {
    return (
      <div>
        <Title order={3}>Lietotājam nav pievienoti projekti!</Title>
      </div>
    );
  }

  return (
    <div>
      {showDialog && <Overlay opacity={0.3} />}
      <Card shadow="sm" padding="lg">
        <div style={{ overflow: "auto" }}>
          {modeList && (
            <SegmentedControl
              style={{ marginTop: 10, marginBottom: 10, width: "100%" }}
              value={form.mode}
              onChange={handleModeChange}
              data={modeList}
            />
          )}
        </div>

        {showDialog && (
          <Dialog
            style={{ display: "flex", justifyContent: "center" }}
            opened={showDialog}
            onClose={handleDialogClose}
            overlayOpacity={0.6}
            title={intl.formatMessage({ id: "countdown" })}
            position={{ top: "45%", left: "45%" }}
          >
            <div style={{ textAlign: "center" }}>
              <Knob
                style={{ display: "flex", justifyContent: "center" }}
                value={countdown}
                readOnly
                size={200}
                min={0}
                max={5}
                showValue={false}
                valueColor={
                  modeList &&
                  modeList.find((item) => item.value === form.mode)?.color
                }
              />
              <div>
                <FormattedMessage id="being_sent_to" />:{" "}
                <span style={{ fontWeight: "800" }}>
                  {" "}
                  {modeList &&
                    modeList.find((item) => item.value === form.mode)?.label}
                </span>
              </div>
              <p>
                <FormattedMessage id="submitting_in" />: {countdown}{" "}
                <FormattedMessage id="seconds" />
              </p>
              <Button
                style={{ color: "white", backgroundColor: "dodgerblue" }}
                onClick={handleDialogClose}
              >
                <FormattedMessage id="cancel" />
              </Button>
            </div>
          </Dialog>
        )}
        {success ? (
          <div style={{ textAlign: "center" }}>
            <h3 style={{ color: theme.colors.green[6] }}>
              <FormattedMessage id="form_submitted" />
            </h3>
            <Button
              style={{ backgroundColor: "lightgreen" }}
              variant="outline"
              onClick={() => setSuccess(false)}
            >
              <FormattedMessage id="submit_another" />
            </Button>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <TextInput
              label={intl.formatMessage({ id: "name" })}
              placeholder={intl.formatMessage({ id: "name" })}
              name="name"
              value={form.name}
              onChange={handleChange}
            />
            <TextInput
              label={intl.formatMessage({ id: "surname" })}
              placeholder={intl.formatMessage({ id: "surname_ph" })}
              name="surname"
              value={form.surname}
              onChange={handleChange}
            />
            <div
              style={{
                marginTop: 10,
                marginBottom: 5,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {!incoming && (
                <div>
                  <Title style={{ fontWeight: 500 }} order={6}>
                    {intl.formatMessage({ id: "phone_nr" })}
                  </Title>
                  <Title order={3}>{form.phoneNumber}</Title>
                  {form.preset && (
                    <div>
                      <Title style={{ fontWeight: 500 }} order={6}>
                        Komentārs par numuru {form.phoneNumber}:
                      </Title>
                      <Title style={{ marginLeft: 5 }} order={6}>
                        {form.preset}
                      </Title>
                    </div>
                  )}
                </div>
              )}

              {incoming && (
                <TextInput
                  label={intl.formatMessage({ id: "phone_nr" })}
                  placeholder={intl.formatMessage({ id: "phone_nr_enter_ph" })}
                  name="phoneNumber"
                  value={form.phoneNumber}
                  onChange={handleChange}
                />
              )}

              <div>
                <Button
                  style={{
                    backgroundColor: incoming ? "red" : "green",
                    zIndex: 5,
                    marginLeft: 15,
                  }}
                  onClick={() => setIncoming(!incoming)}
                  disabled={submitting}
                >
                  {incoming ? "Atcelt" : "Ienākošs zvans!"}
                </Button>
              </div>

              <div>
                {modeList && (
                  <CallButton
                    client={{
                      c_phone: form.phoneNumber,
                      c_name: form.phoneNumber,
                      c_surname: "",
                    }}
                  />
                )}
              </div>
            </div>

            <TextInput
              label={intl.formatMessage({ id: "email" })}
              placeholder={intl.formatMessage({ id: "email_enter_ph" })}
              name="email"
              value={form.email}
              onChange={handleChange}
              error={errors.email}
              errormessage={intl.formatMessage({ id: "enter_valid_email" })}
            />
            <Textarea
              label={intl.formatMessage({ id: "comment" })}
              placeholder={intl.formatMessage({ id: "comment_enter_ph" })}
              name="comment"
              value={form.comment}
              onChange={handleChange}
            />

            {modeList && (
              <div
                style={{
                  marginTop: "1rem",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Button
                    style={{ backgroundColor: "dodgerblue", zIndex: 5 }}
                    type="submit"
                    disabled={submitting}
                  >
                    {submitting ? (
                      <FormattedMessage id="submitting" />
                    ) : (
                      <FormattedMessage id="submit" />
                    )}
                  </Button>

                  <Button
                    style={{
                      backgroundColor: "grey",
                      zIndex: 5,
                      marginLeft: 15,
                    }}
                    onClick={() => submitFail("fail-call")}
                    disabled={submitting}
                  >
                    <FormattedMessage id="didnt_pickup" />
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "orange",
                      zIndex: 5,
                      marginLeft: 15,
                    }}
                    onClick={() => submitFail("not-interested")}
                    disabled={submitting}
                  >
                    <FormattedMessage id="not_interested" />
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "red",
                      zIndex: 5,
                      marginLeft: 15,
                    }}
                    disabled={submitting}
                    onClick={() => submitFail("invalid")}
                  >
                    <FormattedMessage id="invalid_number" />
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "red",
                      zIndex: 5,
                      marginLeft: 15,
                    }}
                    disabled={submitting}
                    onClick={() => submitFail("blacklist")}
                  >
                    Bloķēt!
                  </Button>
                  {/* <Button
                    style={{
                      backgroundColor: "red",
                      zIndex: 5,
                      marginLeft: 15,
                    }}
                    disabled={submitting}
                    onClick={() => console.log(form)}
                  >
                    <FormattedMessage id="test" />
                  </Button> */}
                </div>
                <span
                  style={{
                    fontSize: 40,
                    fontWeight: 800,
                    position: "absolute",
                    right: 0,
                    zIndex: 2,
                    bottom: -12,
                    color:
                      modeList &&
                      modeList.find((item) => item.value === form.mode)?.color,
                    opacity: 0.2,
                  }}
                >
                  {modeList &&
                    modeList.find((item) => item.value === form.mode)?.label}
                </span>
              </div>
            )}
          </form>
        )}
      </Card>
      <div>
        {last && (
          <Card withBorder mt={10}>
            <h3 style={{ fontSize: 18, fontWeight: 600, marginBottom: 10 }}>
              Tavi pēdējie 10 pievienotie
              <br />
              <small style={{ color: "grey" }}>
                Nesakritību gadījumā ziņot
              </small>
            </h3>
            {last &&
              last.map((d) => {
                return <LeadListItem d={d} modeList={modeList} />;
              })}
          </Card>
        )}
      </div>
    </div>
  );
};

export const LeadListItem = ({ d, modeList }) => {
  return (
    <Grid
      style={{
        borderBottom: "solid 1px lightgray",
        justifyContent: "space-between",
      }}
    >
      <Grid.Col xs={5}>
        <div style={{ fontWeight: "600" }}>
          {d?.name} {d?.surname}
        </div>
        <span
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {d.phone && <Phone size={14} />} {d?.phone}
          {d.email && <Mail size={14} style={{ marginLeft: 20 }} />} {d?.email}{" "}
        </span>
      </Grid.Col>
      <Grid.Col xs={5} style={{ display: "block" }}>
        {d.added_by ? (
          <span style={{ color: "grey", fontSize: 14 }}>
            <FormattedMessage id="ar_created_by" />
            {d?.u_name} {d?.u_surname}
          </span>
        ) : (
          ""
        )}
        {d.comment && (
          <span style={{ display: "flex" }}>
            <MessageCircle style={{ marginRight: 5 }} size={14} />
            {d?.comment}
          </span>
        )}
      </Grid.Col>
      <Grid.Col xs={2}>
        <span
          style={{
            fontSize: 14,
            color: "grey",
            textAlign: "right",
          }}
        >
          <div>{d.created?.substring(0, 10)}</div>
          <div>
            {modeList.find((item) => item.lrid === d.system)?.name || d?.system}
          </div>
        </span>
      </Grid.Col>
    </Grid>
  );
};
export default LeadScreen;
