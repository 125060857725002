import { Button } from "@mantine/core";
import { useContext } from "react";
import { WebPhoneContext } from "../functions/WebPhoneContext";
import { Phone } from "tabler-icons-react";
import { FormattedMessage } from "react-intl";

const buttonStyle = {
  flex: 1,
  minWidth: 250,
  maxWidth: 350,
  margin: "5px 10px",
};

function modifyPhoneNumber(phoneNumber) {
  if (!phoneNumber) {
    return "";
  }

  // Remove '00371', '371', or '+371' from the start of the string
  const cleanedNumber = phoneNumber.replace(/^(00371|371|\+371)/, "");

  // Add '+371' to the beginning of the cleaned number
  return `371${cleanedNumber}`;
}

export const CallButton = ({ client }) => {
  const { phone } = useContext(WebPhoneContext);
  const engageCall = () => {
    phone.call(modifyPhoneNumber(client.c_phone));
    phone.info({
      line1: "calling to",
      line2: modifyPhoneNumber(client.c_phone),
      line3: {
        type: "link",
        target: "callback",
        text: client.c_name + " " + client.c_surname,
      },
    });
  };

  phone &&
    phone.event("all", function (data) {
      if (data.event == "RING") {
        let line2;
        if (data.direction == "inbound") {
          line2 = {
            type: "link",
            target: "_blank",
            url: "https://www.google.com/search?q=" + data.caller,
            text: <FormattedMessage id="call_from" /> + data.caller,
          };
        } else {
          line2 = <FormattedMessage id="call_from" /> + data.caller;
        }

        phone.info({
          line1: data.event,
          line2: line2,
          line3: <FormattedMessage id="call_to" /> + data.callee,
        });
      }
    });

  return (
    <Button
      variant="outline"
      color="purple"
      radius={0}
      onClick={engageCall}
      style={{ ...buttonStyle, backgroundColor: "dodgerblue", color: "white" }}
    >
      <Phone
        size={18}
        strokeWidth={2}
        color={"white"}
        style={{ marginRight: 5 }}
      />
      <FormattedMessage id="call_client" />
    </Button>
  );
};
