import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { createUser, getUsers, updateUser } from "../../functions/api";
import ClientTable from "../../Components/ClientTable";
import { Button } from "@mantine/core";
import CreateUserModal from "../../Components/CreateUserModal";
import { Pencil } from "tabler-icons-react";

const AdminUsersScreen = () => {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(null);
  const [open, setOpen] = useState(false);

  const intl = useIntl();

  useEffect(() => {
    const fetchData = async () => {
      const response = await getUsers();
      setUsers(response);
    };
    fetchData();
  }, [open, user]);

  const columns = [
    {
      key: "",
      title: "",
      searchable: false,
      render: (client) => (
        <a style={{ cursor: "pointer" }} onClick={() => openModal(client)}>
          <Pencil />
        </a>
      ),
    },
    {
      key: "email",
      title: "email",
      searchable: true,
      render: (client) => (
        <>
          <span
            style={{
              color: client.status == "disabled" ? "red" : "inherit",
              textDecoration:
                client.status == "disabled" ? "line-through" : "inherit",
            }}
          >
            {client.email}
          </span>
        </>
      ),
    },
    {
      key: "name",
      title: "name",
      searchable: true,
      render: (client) => client.name,
    },
    {
      key: "group",
      title: "group",
      searchable: true,
      render: (client) =>
        client.group_id < 10
          ? intl.formatMessage({ id: "admin" })
          : client.group_id < 20
          ? intl.formatMessage({ id: "user" })
          : intl.formatMessage({ id: "lead" }),
    },
  ];

  const openModal = (data) => {
    setUser(data);
    setOpen(true);
  };

  const handleClose = () => {
    setUser(null);
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleUser = (data) => {
    setUser(null);
    setOpen(false);
    data.userid ? updateUser(data) : createUser(data);
    // successNotif(intl);
  };

  return (
    <>
      {open && (
        <CreateUserModal
          open={open}
          onClose={handleClose}
          onSubmit={handleUser}
          user={user}
        />
      )}
      <Button
        variant="contained"
        style={{ backgroundColor: "dodgerblue", color: "white" }}
        onClick={handleClickOpen}
      >
        <FormattedMessage id="create_user" />
      </Button>
      <ClientTable clients={users} columns={columns} />
    </>
  );
};

export default AdminUsersScreen;
