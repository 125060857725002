import React, { useState, useEffect } from "react";
import {
  Modal,
  TextInput,
  Select,
  Button,
  Group,
  Grid,
  Col,
  MediaQuery,
  useMantineTheme,
  MultiSelect,
  Card,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { FormattedMessage, useIntl } from "react-intl";
import { adminGetLeadClientList } from "../functions/api";

const CreateUserModal = ({ open, onClose, onSubmit, user }) => {
  const theme = useMantineTheme();
  const [projects, setProjects] = useState(null);
  const intl = useIntl();

  const form = useForm({
    initialValues: {
      username: "",
      name: "",
      surname: "",
      email: "",
      password: "",
      status: "",
      access: "",
      group_id: null,
      title: "",
      projects: "",
    },
  });

  useEffect(() => {
    if (open == true) {
    //   adminGetLeadClientList().then((x) => setProjects(x));
      adminGetLeadClientList().then((x) => {
        setProjects(
          x.map((y) => {
            return { label: y.name, value: y.lrid };
          })
        );
      });
    }
  }, [open]);

  useEffect(() => {
    if (user) {
      form.setValues({
        userid: user.userid,
        username: user.username,
        name: user.name,
        surname: user.surname,
        email: user.email,
        status: user.status,
        access: user.access,
        group_id: user.group_id,
        title: user.title,
        projects: user.projects,
      });
    } else {
      form.reset();
    }
  }, [user]);

  useEffect(() => {
    console.log("forms: ", form.values);
  }, [form]);

  const handleSubmit = (values) => {
    onSubmit(values);
  };

  return (
    <Modal
      opened={open}
      onClose={onClose}
      title={
        user ? (
          <FormattedMessage id="modify" defaultMessage="Modify User" />
        ) : (
          <FormattedMessage
            id="create_new_user"
            defaultMessage="Create New User"
          />
        )
      }
      size={"auto"}
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid gutter="md">
          {/* <Col span={12} md={6}>
            <TextInput
              required
              value={form.username}
              onChange={(e) =>
                form.setValues({ ...form.values, username: e.target.value })
              }
              label={
                <FormattedMessage id="username" defaultMessage="Username" />
              }
            />
          </Col> */}
          <Col span={12} md={6}>
            <TextInput
              required
              label={<FormattedMessage id="name" defaultMessage="Name" />}
              value={form.values.name}
              onChange={(e) =>
                form.setValues({ ...form.values, name: e.target.value })
              }
            />
          </Col>
          <Col span={12} md={6}>
            <TextInput
              label={<FormattedMessage id="Surname" defaultMessage="Surname" />}
              value={form.values.surname}
              onChange={(e) =>
                form.setValues({ ...form.values, surname: e.target.value })
              }
            />
          </Col>
          <Col span={12} md={6}>
            <TextInput
              required
              label={<FormattedMessage id="E-mail" defaultMessage="Email" />}
              value={form.values.email}
              onChange={(e) =>
                form.setValues({ ...form.values, email: e.target.value })
              }
            />
          </Col>
          <Col span={12} md={6}>
            <TextInput
              required={!user}
              label={
                !user ? (
                  <FormattedMessage id="password" defaultMessage="Password" />
                ) : (
                  <FormattedMessage
                    id="password_change"
                    defaultMessage="Change Password"
                  />
                )
              }
              value={form.values.password}
              onChange={(e) =>
                form.setValues({ ...form.values, password: e.target.value })
              }
            />
          </Col>
          {user && (
            <Col span={12} md={6}>
              <Select
                label={<FormattedMessage id="status" defaultMessage="Status" />}
                placeholder="Select"
                data={[
                  {
                    value: "disabled",
                    label: intl.formatMessage({ id: "disabled" }),
                  },
                  {
                    value: "active",
                    label: intl.formatMessage({ id: "active" }),
                  },
                ]}
                value={form.values.status}
                onChange={(x) => form.setValues({ ...form.values, status: x })}
              />
            </Col>
          )}
          <Col span={12} md={6}>
            <Select
              label={
                <FormattedMessage id="group_id" defaultMessage="Group ID" />
              }
              placeholder="Select"
              data={[
                { value: "0", label: "Admin" },
                { value: "20", label: "SOME" },
              ]}
              value={form.values.group_id}
              onChange={(x) => form.setValues({ ...form.values, group_id: x })}
            />
          </Col>

          <Col>
            <Card withBorder>
              <MultiSelect
                description="Projekti, pie kuriem tiks pielaists konkrētais lietotājs"
                multiple
                dropdownPosition="bottom"
                value={form.values.projects}
                data={projects && projects.length > 0 ? projects : []}
                label={intl.formatMessage({ id: "project" })}
                onChange={(x) =>
                  form.setValues({ ...form.values, projects: x })
                }
                withinPortal
              />
            </Card>
          </Col>
        </Grid>
        <Group position="right" mt="md">
          <Button variant="default" onClick={onClose}>
            <FormattedMessage id="cancel" defaultMessage="Cancel" />
          </Button>
          <Button type="submit" style={{ backgroundColor: "dodgerblue" }}>
            {form.values.userid ? (
              <FormattedMessage id="update_user" defaultMessage="Update User" />
            ) : (
              <FormattedMessage id="create_user" defaultMessage="Create User" />
            )}
          </Button>
        </Group>
      </form>
    </Modal>
  );
};

export default CreateUserModal;
