import {
  Button,
  Grid,
  MultiSelect,
  Progress,
  Select,
  TextInput,
  Textarea,
  Title,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { adminGetNumberStats } from "../functions/api";

export const MainScreen = () => {
  const [loading, setLoading] = useState(null);
  const [stats, setStats] = useState([]);
  const intl = useIntl();

  useEffect(() => {
    adminGetNumberStats().then((x) => {
      if (!x.error) {
        setStats(x);
      }
    });
  }, []);

  const handleMove = (item, url) => {
    setLoading(item);
    window.location.href = url;
  };
  return (
    <div style={{ margin: 10, marginTop: 10, paddingBottom: 20 }}>
      <Title order={4}>Actions</Title>
      <div>
        <div style={{ marginTop: 20 }}>
          <Grid style={{}}>
            <Grid.Col xs={3}>
              <Button
                fullWidth
                style={{
                  backgroundColor: "indigo",
                }}
                loading={loading == "users"}
                onClick={() => handleMove("users", "/admin-users")}
              >
                {intl.formatMessage({ id: "users" })}
              </Button>
            </Grid.Col>
            <Grid.Col xs={3}>
              <Button
                fullWidth
                style={{
                  backgroundColor: "green",
                }}
                loading={loading == "lead"}
                onClick={() => handleMove("lead", "/admin-lead-overview")}
              >
                {intl.formatMessage({ id: "leads_overview" })}
              </Button>
            </Grid.Col>
            <Grid.Col xs={3}>
              <Button
                fullWidth
                style={{
                  backgroundColor: "orange",
                }}
                loading={loading == "import"}
                onClick={() => handleMove("import", "/admin-imports")}
              >
                {intl.formatMessage({ id: "import" })}
              </Button>
            </Grid.Col>
            <Grid.Col xs={3}>
              <Button
                fullWidth
                style={{
                  backgroundColor: "dodgerblue",
                }}
                loading={loading == "numbers"}
                onClick={() => handleMove("numbers", "/admin-numbers")}
              >
                {intl.formatMessage({ id: "number_management" })}
              </Button>
            </Grid.Col>
            <Grid.Col xs={3}>
              <Button
                fullWidth
                style={{
                  backgroundColor: "dodgerblue",
                }}
                loading={loading == "projects"}
                onClick={() => handleMove("projects", "/admin-projects")}
              >
                {intl.formatMessage({ id: "projects" })}
              </Button>
            </Grid.Col>
            {/* <Grid.Col xs={3}>
              <Button
                fullWidth
                style={{
                  backgroundColor: "dodgerblue",
                }}
                loading={loading == "clients"}
                onClick={() => handleMove("clients", "/admin-clients")}
              >
                {intl.formatMessage({ id: "clients" })}
              </Button>
            </Grid.Col> */}
          </Grid>
        </div>
      </div>
      {/* <Button
              style={{
                backgroundColor: "teal",
                flex: 1,
                minWidth: 250,
                maxWidth: 400,
                
              }}
              loading={loading == "campaigns"}
              onClick={() => handleMove("campaigns", "/admin-campaigns")}
            >
              {intl.formatMessage({ id: "campaigns" })}
            </Button> */}
      <div style={{ marginTop: 20 }}>
        {stats && stats.length > 0 && <Title order={4}>Stats</Title>}
        {stats &&
          stats?.map((dt, index) => {
            const percentage =
              (Number(dt.numbers_used) / Number(dt.total_numbers)) * 100;
            return (
              <div style={{ margin: 10 }}>
                <Title order={4}>{dt.project_name}</Title>
                <small style={{ fontSize: 10 }}>
                  {`${dt.numbers_used} / ${
                    dt.total_numbers
                  } (${percentage.toFixed(2)}%)`}
                </small>
                <Progress
                  radius="xl"
                  size={24}
                  sections={[
                    {
                      value: percentage,
                      color:
                        percentage > 50
                          ? percentage > 80
                            ? "red"
                            : "orange"
                          : "dodgerblue",
                      label: `${dt.numbers_used} / ${
                        dt.total_numbers
                      } (${percentage.toFixed(2)}%)`,
                      tooltip: "Izmantotie numuri",
                    },
                  ]}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

{
  /* <div>
<h3>Import campaign</h3>
<MultiSelect label="Select clients" data={[]} />
<TextInput label="Campaign name" />
<Textarea label="Phone numbers" />
</div> */
}
