import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { getData, getGroup, getUserId } from "../functions/tokens";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { env } from "../env";
import { FormattedMessage } from "react-intl";
// import OverlayCombined from "./Overlays/OverlayCombined";
import { X, Menu2, Calendar, User } from "tabler-icons-react";
// import { getEventNotifications } from "../functions/api";
// import { Indicator } from "@mantine/core";
// import { EventCard } from "./Cards/EventCard";
import { useSettings } from "../Hooks/SettingsContext";

const image = require(`../assets/m_logo.png`);

const userNavigation = [
  { name: "Your Profile", href: "/profile" },
  { name: "Sign out", href: "/logout" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MainContainer({
  current,
  show,
  children,
  locale,
  showTop = true,
  marginOffset = true,
}) {
  const { general } = { x: null }; //useSettings();
  const [group, setGroup] = useState(null);

  const [sidemodal, setSidemodal] = useState(false);
  const [modalData, setModaldata] = useState();
  const [overlay, setOverlay] = useState(false);
  const [ovData, setOvdata] = useState();
  const [events, setEvents] = useState();
  const [notif, setNotif] = useState();
  const [showCalendar, setShowCalendar] = useState(false);

  //   useEffect(() => {
  //     setShowCalendar(
  //       (show && general && general.calendar == 1) ||
  //         (general && getGroup() < 10 && general.adminCalendar == 1)
  //     );
  //   }, [general]);

  const color = show && {
    color: general && general.revColor ? general.revColor : "white",
  };

  const [{ data }, setData] = useState(
    getData() || { data: { email: "", name: "" } }
  );

  const user = show && {
    name: data?.name,
    email: data?.email,
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png",
  };

  const nav = useNavigate();

  // const { locale, changeLocale } = useLanguage();

  const handleLanguageChange = (newLocale) => {
    localStorage.setItem("locale", newLocale);
    window.location.reload();
  };

  // hasAccess(group, "reports") && {
  //   name: <FormattedMessage id="marketing" />,
  //   href: "#",
  //   current: false,
  //   route: "marketing/campaigns",
  // },
  useEffect(() => {
    setGroup(getGroup());
  }, [nav]);

  useEffect(() => {
    if (showCalendar)
      if (getUserId() && getGroup() && getGroup() != 20) {
        // const loadNotifs = () => {
        //   getEventNotifications().then((x) => {
        //     if (notif?.length != x?.length) {
        //       setEvents(x);
        //       setNotif(x.length);
        //     }
        //   });
        // };

        //loadNotifs();

        const interval = setInterval(() => {
          //loadNotifs();
        }, 30000); //60000

        return () => clearInterval(interval);
      }
  }, [general]);

  return (
    <>
      {show ? (
        <div className="min-h-full">
          <Disclosure
            as="nav"
            className=""
            // style={{ backgroundColor: "rgb(55, 108, 202)" }}
            style={{
              backgroundColor:
                general && general.sysColor
                  ? general.sysColor
                  : "rgb(31, 41, 55)",
            }}
          >
            {({ open }) => (
              <>
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                  <div className="flex h-16 items-center justify-between">
                    <div className="flex items-center">
                      <div className="flex-shrink-0">
                        <a href={"/"}>
                          <img
                            className="h-8"
                            src={image}
                            style={{
                              filter: `brightness(100) ${
                                general && general.invert ? "invert(1)" : ""
                              }`,
                            }}
                          />
                        </a>
                      </div>
                      <div className="hidden md:block">
                        <div className="ml-10 flex items-baseline space-x-4">
                          {/* {navigation?.map((item) => (
                            <a
                              key={item.route + "a4"}
                              style={color}
                              href={"/" + item.route}
                              className={classNames(
                                item.route == current
                                  ? "bg-gray-900 text-white"
                                  : `${
                                      general && general.revColor
                                        ? ""
                                        : "text-gray-300"
                                    } hover:bg-gray-700 hover:text-white`, //"text-gray-300 hover:bg-gray-700 hover:text-white",
                                "px-3 py-2 rounded-md text-sm font-medium"
                              )}
                              aria-current={
                                item.current ? (
                                  <FormattedMessage id="page" />
                                ) : undefined
                              }
                            >
                              {item.name}
                            </a>
                          ))} */}
                        </div>
                      </div>
                    </div>
                    <div className="hidden md:block">
                      <div className="ml-4 flex items-center md:ml-6">
                        <Menu as="div" className="relative mr-5">
                          <div>
                            <Menu.Button
                              style={{
                                padding: 3,
                                paddingLeft: 5,
                                paddingRight: 5,
                                ...color,
                              }}
                              className="flex max-w-xs items-center rounded-full text-sm outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800"
                            >
                              <span className="sr-only">
                                <FormattedMessage id="open_user_menu" />
                              </span>
                              {locale == "en-GB"
                                ? "EN \uD83C\uDDEC\uD83C\uDDE7"
                                : "LV \uD83C\uDDF1\uD83C\uDDFB"}
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              style={{ width: 70 }}
                              className="absolute right-0 z-10 mt-2 w-22 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                            >
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    //href={locale == "en-GB" ? "/lv" : "/en"}
                                    onClick={() =>
                                      handleLanguageChange(
                                        locale == "en-GB" ? "lv-LV" : "en-GB"
                                      )
                                    }
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block px-4 py-2 text-sm text-gray-700"
                                    )}
                                  >
                                    {locale == "en-GB"
                                      ? "LV \uD83C\uDDF1\uD83C\uDDFB"
                                      : "EN \uD83C\uDDEC\uD83C\uDDE7"}
                                  </a>
                                )}
                              </Menu.Item>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                        <span style={color}>{user.name}</span>
                        <Menu as="div" className="relative ml-3">
                          <div>
                            <Menu.Button className="flex max-w-xs items-center rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                              <span className="sr-only">
                                <FormattedMessage id="open_user_menu" />
                              </span>
                              <User size={30} style={color} />
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              {userNavigation.map((item) => (
                                <Menu.Item key={item.href + "a1"}>
                                  {({ active }) => (
                                    <a
                                      href={item.href}
                                      className={classNames(
                                        active ? "bg-gray-100" : "",
                                        "block px-4 py-2 text-sm text-gray-700"
                                      )}
                                    >
                                      {item.name}
                                    </a>
                                  )}
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    </div>
                    <div className="-mr-2 flex md:hidden">
                      {/* Mobile menu button */}
                      <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <span className="sr-only">
                          <FormattedMessage id="open_main_menu" />
                        </span>
                        {open ? (
                          <X
                            style={color}
                            className="block h-6 w-6"
                            aria-hidden="true"
                          />
                        ) : (
                          <Menu2
                            style={color}
                            className="block h-6 w-6"
                            aria-hidden="true"
                          />
                        )}
                      </Disclosure.Button>
                    </div>
                  </div>
                </div>

                <Disclosure.Panel className="md:hidden">
                  <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
                    {/* {navigation.map((item) => (
                      <Disclosure.Button
                        key={item.route + "a2"}
                        as="a"
                        href={"/" + item.route}
                        className={classNames(
                          item.current
                            ? "bg-gray-900 text-white"
                            : "text-gray-300 hover:bg-gray-700 hover:text-white",
                          "block px-3 py-2 rounded-md text-base font-medium"
                        )}
                        aria-current={
                          item.current ? (
                            <FormattedMessage id="page" />
                          ) : undefined
                        }
                        style={color}
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))} */}
                  </div>
                  <div className="border-t border-gray-700 pt-4 pb-3">
                    <div className="flex items-center px-5">
                      <div className="flex-shrink-0">
                        <User size={35} style={color} />
                      </div>
                      <div className="ml-3">
                        <div
                          style={color}
                          className="text-base font-medium leading-none text-white"
                        >
                          {user.name}
                        </div>
                        <div
                          style={color}
                          className="text-sm font-medium leading-none text-gray-400"
                        >
                          {user.email}
                        </div>
                      </div>
                    </div>
                    <div className="mt-3 space-y-1 px-2">
                      {userNavigation.map((item) => (
                        <Disclosure.Button
                          key={item.route + "a3"}
                          as="a"
                          href={item.href}
                          style={color}
                          className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                        >
                          {item.name}
                        </Disclosure.Button>
                      ))}
                    </div>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <main>
            <div className="mx-auto max-w-7xl py-4 sm:px-4 lg:px-4">
              <div className="px-4 sm:px-0" style={{ flex: 4, flexBasis: 300 }}>
                {/* <ActionBar action={handleAction} /> */}
                {/* {group != 20 && showTop && (
                  <OverlayCombined
                    sidemodal={sidemodal}
                    setSidemodal={setSidemodal}
                    modalData={modalData}
                    setModaldata={setModaldata}
                    overlay={overlay}
                    setOverlay={setOverlay}
                    ovData={ovData}
                    setOvdata={setOvdata}
                    hideClients={general && general.limitClients == 1}
                  />
                )} */}
              </div>
              {children}
            </div>
          </main>
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
}
